<template>
  <div class="row">
    <div class="col-12">
      <!-- 평가대상 목록 - 유해위험요인 -->
      <c-table
        ref="table"
        title="평가대상 목록 - 유해위험요인"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.thirdScenarios"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :filtering="false"
        :editable="editable&&!disabled&&isSelect"
        selection="multiple"
        rowKey="ramThirdAssessScenarioId"
      >
        <!-- @rowClick="rowClick" -->
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect" label="추가" icon="add" @btnClicked="addRiskHazard" />
            <c-btn v-show="editable&&!disabled&&isSelect&&process.thirdScenarios&&process.thirdScenarios.length>0" 
            label="삭제" icon="remove" @btnClicked="removeRiskHazard" />
            <c-btn 
              v-show="editable&&!disabled&&isSelect&&process.thirdScenarios&&process.thirdScenarios.length>0" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="process.thirdScenarios"
              mappingType="PUT"
              label="저장" 
              icon="save"
              @beforeAction="saveRiskHazard"
              @btnCallback="saveRiskHazardCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='picture'">
            <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-success" 
              name="image"
              @click.stop="openPicture(props)" /> 
              <!-- <c-upload-picture
                class="krasScenario"
                height="35px"
                :attachInfo="{
                  isSubmit: '',
                  taskClassCd: 'THIRD_RISK_HAZARD',
                  taskKey: props.row.ramThirdAssessScenarioId,
                }"
              ></c-upload-picture> -->
          </template>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'Third-target-process',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        thirdScenarios: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            label: '유해위험요인 (위험한 상황과 사건)',
            align: 'left',
            style: 'width:100%',
            sortable: true,
            type: 'textarea',
            required: true,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '사진',
            align: 'center',
            type: 'custom',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: 'auto' //'500px'
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.third.scenario.list.url
      this.saveUrl = transactionConfig.ram.third.scenario.save.url
      this.deleteUrl = transactionConfig.ram.third.scenario.delete.url
      // code setting
      // list setting
    },
    getScenarios() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.thirdScenarios = _result.data
      },);
    },
    addRiskHazard() {
      if (!this.process.thirdScenarios) this.process.thirdScenarios = [];
      this.process.thirdScenarios.splice(0, 0, {
        ramThirdAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
        processCd: this.process.processCd,  // 단위공정 코드
        processName: this.process.processName,  // 단위공정
        riskHazardQuestionName: '',  // 유해위험요인 질문
        ramRiskThirdStandardId: '',  // 3단계 판단결과
        riskColor: '',  // 위험색상
        regUserId: this.$store.getters.user.userId,  // 작성자 ID
        editFlag: 'C'
      })
    },
    saveRiskHazard() {
      if (this.$comm.validTable(this.grid.columns, this.process.thirdScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRiskHazardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getScenarios();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeRiskHazard() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = '삭제하시겠습니까?'; // 삭제하시겠습니까?
        if (this.updateBtnData.flag) {
          // 현재 평가실행 중인 유해위험요인입니다.\n\r관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n\r진행하시겠습니까?
          message = '재 평가실행 중인 유해위험요인입니다.\n\r관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n\r진행하시겠습니까?'
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: message,
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.thirdScenarios = this.$_.reject(this.process.thirdScenarios, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdRiskHazardPicture.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePicturePopup;
    },
    closePicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
